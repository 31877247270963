// extracted by mini-css-extract-plugin
export var heroHeaderLeft = "r_ry d_gv d_cs";
export var heroHeaderCenter = "r_gw d_gw d_cs d_dw";
export var heroHeaderRight = "r_gx d_gx d_cs d_dx";
export var heroParagraphLeft = "r_rz d_gr d_cw";
export var heroParagraphCenter = "r_gs d_gs d_cw d_dw";
export var heroParagraphRight = "r_gt d_gt d_cw d_dx";
export var heroBtnWrapperLeft = "r_rB d_d2 d_d1 d_w d_bz d_bG";
export var heroBtnWrapperCenter = "r_rC d_d3 d_d1 d_w d_bz d_bD";
export var heroBtnWrapperRight = "r_rD d_d4 d_d1 d_w d_bz d_bH";
export var overlayBtnWrapper = "r_rF d_gq d_0 d_7 d_8 d_9 d_bn d_cc";
export var design4 = "r_rG d_gp d_0 d_7 d_8 d_bn";
export var heroExceptionSmall = "r_rH v_rH";
export var heroExceptionNormal = "r_rJ v_rJ";
export var heroExceptionLarge = "r_rK v_rK";
export var Title1Small = "r_rL v_rL v_rX v_rY";
export var Title1Normal = "r_rM v_rM v_rX v_rZ";
export var Title1Large = "r_rN v_rN v_rX v_r0";
export var BodySmall = "r_rP v_rP v_rX v_sf";
export var BodyNormal = "r_rQ v_rQ v_rX v_sg";
export var BodyLarge = "r_rR v_rR v_rX v_sh";