// extracted by mini-css-extract-plugin
export var lbContainerOuter = "y_tV";
export var lbContainerInner = "y_tW";
export var movingForwards = "y_tX";
export var movingForwardsOther = "y_tY";
export var movingBackwards = "y_tZ";
export var movingBackwardsOther = "y_t0";
export var lbImage = "y_t1";
export var lbOtherImage = "y_t2";
export var prevButton = "y_t3";
export var nextButton = "y_t4";
export var closing = "y_t5";
export var appear = "y_t6";